import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SectionDivider from "./atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../resources/images/right-arrow.svg"


export default function ReferencesBoxes() {

  const data = useStaticQuery(graphql`{
  webRef: file(relativePath: {eq: "gyg_show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef2: file(relativePath: {eq: "tjlforms-box.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  webRef3: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef4: file(relativePath: {eq: "tasty-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)



  return (
    <div className="section-pd section-pd-bottom white-text white-bg-cut-reverse">
      <div className="container">
        <SectionDivider name="references" white={true}/>
        <div className="row">
          <div className="col-4 section-heading-white">
            <h2>Pozrite sa na čom sme pracovali.</h2>
          </div>
          <div className="col-4 section-subtitle-white mt20 mtm20">
            <p>Robili sme na zaujímavých projektoch, projekty, ktoré pomáhajú ľuďom. Veď presvedčte sa sami.</p>
          </div>
        </div>

        <div className="row mt40">

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/gyg" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Softvér na mieru" />
              <div className="ref-button">
                Softvér na mieru
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>

          <div className="col-6 ">
          <AniLink cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef2.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Webová aplikácia" />
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
        <div className="row mt40">

          <div className="col-6">
          <AniLink cover direction="rigleftht" to="/referencie/doku" bg="#000" duration={1}><div className="ref-card">
              <GatsbyImage
                image={data.webRef3.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Webová aplikácia" />
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div></AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef4.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Mobilná aplikácia" />
              <div className="ref-button">
                Mobilná aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>

        <div className="row mt60">
          <div className="cta-link"><AniLink cover direction="left" to="/referencie" bg="#000" duration={1}>Pozrieť viac referencií</AniLink></div>
        </div>
          
           
      </div>
          
    </div>
  );
  
}


