import React from "react"
import SectionDivider from "./atomic/section-divider"

export default function Testemonials() {

  


  return (
    <div className="section-pd-bottom white-bg">
      <div className="container">
        <SectionDivider name="Testemonials" white={false}/>
        <div className="row">
          <div className="col-4 section-heading-black">
            <h2>Spokojní klienti s nejednoduchými úlohami.</h2>
          </div>
          <div className="col-4 section-subtitle-black mt20">
            <p>Pracovali sme na náročných, ale aj menej náročných projektoch. <span style={{"fontWeight":"600"}}>Jedno ich spája a to, že ich robíme radi.</span></p>
          </div>
        </div>

        <div className="row mt40">
          
          
          <div className="col-4">
            <div className="blockquote-wrapper">
              <div className="blockquote">
                <h3>
                “Používate výborné manažérske techniky, niektoré sme využili aj v našich firmách. Projekty sa vyrábajú ako po masle.”
                </h3>
                <h4>&mdash; Mgr. Marián Varga<br /><em> TJ-Legal - vrátenie daní a rodinných prídavkov zo Slovenska a zahraničia</em></h4>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="blockquote-wrapper">
              <div className="blockquote">
                <h3>
                “Skúsenosti Vášho tímu nám ušetrili množstvo času pri návrhu riešenia ale aj realizácii. Tešíme sa na ďalšie projekty s Vami.”
                </h3>
                <h4>&mdash; Ing. Ján Ďurta<br /><em> RAWACON - Inšpekcia výroby vagónových súprav</em></h4>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="blockquote-wrapper">
              <div className="blockquote">
                <h3>
                “Páči sa nám Váš dôraz na efektivitu, firme to ušetrilo peniaze a výsledok je vo vysokej kvalite. Tešíme sa z našej spolupráce.”
                </h3>
                <h4>&mdash; Ing. Peter Kažimír<br /><em> Energocontrol - Automatizačné systémy pre priemysel a energetiku</em></h4>
              </div>
            </div>
          </div>
          
          
          
        </div>
          
           
      </div>
          
    </div>


    )
  
}


