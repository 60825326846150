import React from "react"
import Layout from "../components/layout"
import HeroMain from "../components/heromain"
import ServicesShow from "../components/services-show"
import ReferencesBoxes from "../components/references-boxes"
import Testemonials from "../components/testemonials"
import Benefits from "../components/benefits"
import SEO from "../components/seo"




export default function IndexPage() {

return (
      <Layout>
      <SEO title="Vývoj softvéru na mieru" />
      
      <HeroMain/>
      <ServicesShow></ServicesShow>
      <Benefits></Benefits>
      <ReferencesBoxes></ReferencesBoxes>
      <Testemonials></Testemonials>
    </Layout>
    )
  
}


