import React from "react"



export default function HeroMain() {

  
  
  


  return (
    <div className="container">
    <div className={"heromain"}>
                
                <h1>Robíme <span style={{color: "#F94166"}}>weby</span>, webové a mobilné <span style={{color: "#F94166"}}>aplikácie</span> na mieru, <span style={{color: "#F94166"}}>umelú inteligenciu</span> a <span style={{color: "#F94166"}}>bigdata</span>.</h1>
                
               <div className="row float-bottom"><p className={"services-small"}>UX & UI Design  |  Web & Web App Development  |  Mobile App Development  |  Artificial Intelligence </p></div>

               
                
                
              
        
      </div>
      </div> 
    )
  
}


